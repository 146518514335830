/** @format */
import {
  inject,
  customElement,
  bindable
} from 'aurelia-framework';
import './text-input-cctv.scss';

@customElement('text-input-cctv')
@inject(Element)
export class TextInput {
  @bindable value;
  @bindable name;
  @bindable inputRef;
  @bindable elementRef;
  @bindable type;
  @bindable label;
  @bindable postLabel;
  @bindable placeholder;
  @bindable cssClass;
  @bindable inputCssClass;
  @bindable readonly;
  @bindable disabled;
  @bindable required
  @bindable keepActive;
  @bindable maxlength;
  @bindable autofocus;
  @bindable autoselect;
  @bindable autocomplete;
  @bindable pattern;
  @bindable dataError;
  @bindable color;
  @bindable cctvStatus;
  @bindable snapshot;
  @bindable cctvDropdownStyle;
  
  @bindable step;
  @bindable min;
  @bindable max;

  @bindable leadIcon;
  @bindable trailIcon;

  @bindable change;

  constructor(element) {
    this.element = element
    this.value = null;
    this.name = null;
    this.type = 'text';
    this.label = null;
    this.postLabel = null;
    this.dataError = '';
    this.cssClass = '';
    this.inputCssClass = '';
    this.readonly = null;
    this.disabled = null;
    this.keepActive = false;
    this.autofocus = false;
    this.autoselect = false;
    this.color = '';

    this.leadIcon = null;
    this.trailIcon = null;

    this.onChange = this.onChange.bind(this);
  }

  attached() {
    if (this.autofocus) {
      this.inputRef.setAttribute('aurelia-autofocus', true);
      this.inputRef.focus()
    }
    if (this.autoselect) {
      this.inputRef.select()
    }
    if (this.autocomplete) {
      this.inputRef.setAttribute('autocomplete', this.autocomplete);
    }
    if (this.placeholder) {
      this.inputRef.setAttribute('placeholder', this.placeholder);
    }
    if (this.required) {
      this.inputRef.setAttribute('required', this.required);
    }
    if (this.pattern) {
      this.inputRef.setAttribute('pattern', this.pattern);
    }
    if (this.min) {
      this.inputRef.setAttribute('min', this.min);
    }
    if (this.max) {
      this.inputRef.setAttribute('max', this.max);
    }
    if (this.step) {
      this.inputRef.setAttribute('step', this.step);
    }
    if (this.maxlength) {
      this.inputRef.setAttribute('maxlength', this.maxlength);
    }
    if (this.dataError) {
      this.inputRef.setAttribute('title', this.dataError);
    }
    
    this.elementRef = this.inputRef;
  }

  onBlur(event) {
    this.elementFocusStatus(event, false)
  }

  onFocus(event) {
    this.elementFocusStatus(event, true)
  }

  elementFocusStatus(event, status) {
    var eventDetails = new CustomEvent('element-status-changed', {
      detail: {
        event,
        status
      },
      bubbles: true
    });

    this.element.dispatchEvent(eventDetails);
  }

  onChange(e) {
    if (this.change) {
      this.change(e);
    }
  }
}
